import * as ClientConfig from "../config/ClientConfig.js";
//analytics
import {initializeApp} from "firebase/app";
import {getAnalytics, setUserProperties,logEvent} from "firebase/analytics";
import * as StorageService from "./StorageServices";
import {LOCALSTORAGE_ITEMS} from "../util/Constants";


const firebaseConfig = {
    apiKey: ClientConfig.REACT_APP_FIREBASE_API_KEY,
    authDomain: ClientConfig.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: ClientConfig.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: ClientConfig.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: ClientConfig.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: ClientConfig.REACT_APP_FIREBASE_APP_ID,
    measurementId: ClientConfig.REACT_APP_FIREBASE_MEASUREMENT_ID
};
// Initialize Firebase
let app = null;
let analytics = null;
let analyticsInitialized = false;
function analyticsLogEvent(event, params){
    if (!analyticsInitialized) {
        analyticsInitialized = true;
        app = initializeApp(firebaseConfig);
        analytics = getAnalytics(app);
        setUserProperties(analytics, { domain: ClientConfig.APP_DOMAIN });
    }

    const finalParams = {
        ...params,
        origin: ClientConfig.APP_DOMAIN,
        client_id: StorageService.getItem(LOCALSTORAGE_ITEMS.USER_ID)
    }
    logEvent(analytics, event, finalParams);
}
export default analyticsLogEvent;
